import { IHttpClient } from '@wix/yoshi-flow-editor';

type InPopulationResponse = {
  inPopulation: boolean;
};

type IsOrderProvisionedResponse = {
  isProvisioned: boolean;
  hasGroup: boolean;
};

export class BenefitPrograms {
  constructor(private httpClient: IHttpClient) {}

  public async isInPopulation() {
    const response = await this.httpClient.get<InPopulationResponse>('/_serverless/pricing-plans-bpp/in-population');
    const { inPopulation } = response.data;
    return inPopulation;
  }

  public async isOrderProvisioned(orderId: string) {
    const response = await this.httpClient.get<IsOrderProvisionedResponse>(
      `/_serverless/pricing-plans-bpp/is-order-provisioned/${orderId}`,
    );
    const { isProvisioned, hasGroup } = response.data;
    return isProvisioned && hasGroup;
  }
}
